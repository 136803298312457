<template>
  <div style="position: relative">
    <!--页面加载有问题就在order-main加上 v-if="info"-->
    <div class="order-main">
      <div class="header-top">
        <div class="left-wrap">
          <div class="header-title">当前学员</div>
          <div class="student-info">
            <div class="student-avtor">
              <img :src="studentProfile.headImageUrl ? studentProfile.headImageUrl : defaultUrl" class="student-pic" />
            </div>

            <div style="margin-left: 12px">
              <div>
                <span>{{ studentProfile.fullName }}</span>
                <span style="color: rgb(102, 102, 102)">（{{ studentProfile.code }}）</span>
                <a-tag color="green">
                  审核{{
                    initTabsList(info.orderData.approveStatus, approvalStatus).length > 0
                      ? initTabsList(info.orderData.approveStatus, approvalStatus)[0].label
                      : ''
                  }}
                </a-tag>
                <a-tag color="green">
                  订单{{
                    initTabsList(info.orderStatus, orderStatusArray).length > 0
                      ? initTabsList(info.orderStatus, orderStatusArray)[0].label
                      : ''
                  }}
                </a-tag>
                <a-tag color="green">
                  物流{{
                    initTabsList(info.orderData.logisticsStatus, deliveryStatus).length > 0
                      ? initTabsList(info.orderData.logisticsStatus, deliveryStatus)[0].label
                      : ''
                  }}
                </a-tag>
                <a-tag color="green" v-if="info.refund.length > 0 && statusMapper[info.refund[0].refundStatus]">
                  {{ statusMapper[info.refund[0].refundStatus] }}
                </a-tag>
              </div>
              <div class="order-customerPhone">
                <a @click="zoomMobleFun">{{ info.orderShipment.receiverPhone }}</a> |
                {{ info.orderData.countryEnName }}
              </div>
            </div>
          </div>
        </div>
        <div class="right-wrap">
          <a-button size="small" v-if="showUnsubscription" @click="handleUnSubscription" :loading="iconLoading">
            取消订阅
          </a-button>
          <a-button size="small" v-if="showRefund" @click="applyForRefund" :loading="iconLoading"> 申请退款 </a-button>

          <a-button
            size="small"
            v-if="
              info.orderData.approveStatus === 'PASSED' &&
              info.orderStatus === 'PENDING_PAYMENT' &&
              (info.orderData.logisticsStatus === 'PENDING_CONFIRM' ||
                info.orderData.logisticsStatus === 'NO_NEED_TO_SHIP')
            "
            @click="onOrderInfo"
            >申请财务到账</a-button
          >

          <template>
            <a-tooltip v-bind="info.orderData.approveStatus !== 'PASSED' ? {} : { visible: false }">
              <template slot="title">当前订单未完成审批</template>
              <a-button
                size="small"
                v-if="
                  userInfo.roleName === 'CLASS_ADMIN' &&
                  info.orderStatus !== 'CANCELLED' &&
                  info.orderStatus !== 'PAYMENT_RECEIVED'
                "
                @click="sendOrder"
                :disabled="info.orderData.approveStatus !== 'PASSED'"
              >
                发送订单
              </a-button>
            </a-tooltip>
          </template>

          <a-button
            :loading="receiptLoading"
            size="small"
            @click="downReceipt"
            v-if="info.orderStatus === 'PAYMENT_RECEIVED' || info.orderStatus === 'CONFIRMED'"
            >下载Receipt</a-button
          >
          <a-button
            size="small"
            :loading="invoiceLoading"
            @click="downInvoice"
            v-if="info.orderStatus === 'PAYMENT_RECEIVED' || info.orderStatus === 'CONFIRMED'"
            >下载invoice</a-button
          >
          <a-button size="small" v-if="info.orderStatus === 'PENDING_PAYMENT'" @click="cancalOrder">取消订单</a-button>
        </div>
      </div>
      <div class="order-wrap">
        <div class="order-info">
          <div v-if="info">
            <div>
              <div class="order-title">
                <p>
                  商品组合
                  <a-icon
                    @click="editOrder"
                    type="edit"
                    v-if="
                      info.orderData.approveStatus === 'PASSED' &&
                      info.orderStatus === 'PAYMENT_RECEIVED' &&
                      info.orderData.logisticsStatus === 'PENDING_CONFIRM'
                    "
                  />
                </p>
              </div>
              <template>
                <div class="order-commodity" v-for="i in info.orderLines" :key="i.uuid">
                  <div
                    class="num"
                    v-if="
                      i.productSnapshot &&
                      i.productSnapshot.packageItems &&
                      i.productSnapshot.packageItems.length !== 0 &&
                      i.productSnapshot.packageItems[0].productSnapshot.images
                    "
                  >
                    <img :src="i.productSnapshot.packageItems[0].productSnapshot.images[0].url" alt="" />
                  </div>
                  <div class="commodity-info">
                    <div>
                      <p>
                        <a-popover
                          title="商品详情"
                          v-if="i.productSnapshot.packageItems && i.productSnapshot.packageItems.length > 0"
                        >
                          <template slot="content">
                            <div>
                              <p v-for="(item, index) in i.productSnapshot.packageItems" :key="index">
                                {{ item.productSnapshot.name }}x{{ item.quantity }}
                              </p>
                            </div>
                          </template>
                          <span class="title" style="cursor: pointer">{{ i.productSnapshot.name }}</span>
                        </a-popover>
                        <span class="title" v-else>{{ i.productSnapshot.name }}</span>

                        <span v-for="item in i.productSnapshot.tags" :key="item">
                          <span class="commodity-new" v-if="['CHINESE', 'MATH'].find((k) => item == k)">
                            {{
                              initTabsList(item, tabsList).length !== 0 ? initTabsList(item, tabsList)[0].label : ' '
                            }}
                          </span>
                        </span>
                      </p>
                      <p class="number">x{{ i.quantity }}</p>
                    </div>
                    <div class="price">
                      <p>
                        原价{{ info.actualPaymentAmount.currency }}:
                        {{
                          getOriginalPrice(info.actualPaymentAmount.currency, i.productSnapshot.prices).length > 0
                            ? getOriginalPrice(info.actualPaymentAmount.currency, i.productSnapshot.prices)[0].value
                            : ''
                        }}
                        优惠:{{ i.discount }}% 优惠{{ info.actualPaymentAmount.currency }}: {{ i.discount }}
                        <template v-if="info.coupons && info.coupons.length">
                          代金券{{ info.coupons[0].currency }}: {{ info.coupons[0].deductedAmount }}
                        </template>
                        税{{ info.actualPaymentAmount.currency }}:
                        {{
                          info.actualPaymentAmount.currency === 'NZD'
                            ? (getOriginalPrice(info.actualPaymentAmount.currency, i.productSnapshot.prices).length > 0
                                ? getOriginalPrice(info.actualPaymentAmount.currency, i.productSnapshot.prices)[0]
                                    .value * 0.15
                                : ''
                              ).toFixed(2)
                            : 0
                        }}
                      </p>
                      <p class="actuallyPaid">
                        实付 {{ info.actualPaymentAmount.currency }}
                        {{ info.actualPaymentAmount.value && info.actualPaymentAmount.value.toFixed(2) }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="order-remark">
                  <p>
                    订单备注：<span>{{ info.orderShipment.instruction }}</span>
                  </p>
                  <div>
                    <div class="all-price">
                      <p>
                        总价：<span>{{ allPaice }}</span>
                      </p>
                      <!-- <p>
                    优惠总计：<span>{{ (allPaice - info.actualPaymentAmount.value).toFixed(2) }}</span>
                  </p> -->
                      <p>
                        税总计：
                        <span>
                          {{
                            info.actualPaymentAmount.currency === 'NZD'
                              ? (info.actualPaymentAmount.value * 0.15).toFixed(2)
                              : '0.00'
                          }}</span
                        >
                      </p>
                      <!-- <p>运费：<span>238.00</span></p> -->
                      <p>
                        币种：<span>{{ info.actualPaymentAmount.currency }}</span>
                      </p>
                    </div>
                  </div>
                  <p class="total">
                    实付总计：
                    <span>{{ info.actualPaymentAmount.value && info.actualPaymentAmount.value.toFixed(2) }}</span>
                  </p>
                </div>
              </template>

              <div class="order-title">
                <p>支付信息</p>
              </div>
              <div class="order-items" v-if="!expand['payment']">
                <div class="send-unit">
                  <span>财务审核人:</span>
                  <span
                    class="send-text"
                    v-if="info.payment && info.payment.customMessage && info.payment.customMessage.reviewer"
                  >
                    {{ info.payment.customMessage.reviewer.name }}
                  </span>
                </div>
                <div class="send-unit">
                  <span>审核时间:</span>
                  <span
                    class="send-text"
                    v-if="info.payment && info.payment.customMessage && info.payment.customMessage.reviewer"
                    >{{ $moment(info.payment.customMessage.reviewer.time).format('YYYY年MM月DD日 HH:mm:ss') }}</span
                  >
                </div>
                <div class="send-unit">
                  <span>财务确认状态：</span>
                  <span class="send-text" v-if="info.payment">
                    {{ initTabsList(info.payment.paymentStatus, confirmStatus)[0].label }}
                    <span v-if="info.payment.paymentStatus === 'SUCCESSFUL'"
                      >（业绩：${{ (info.payment.paymentAmount * info.payment.usdExchangeRate).toFixed(2) }}）</span
                    >
                  </span>
                </div>
                <div class="send-unit">
                  <span>支付方式：</span>
                  <span class="send-text" v-if="info.payment">
                    {{ info.payment.paymentMethod }}
                  </span>
                </div>
                <div class="send-unit">
                  <span>VA账号：</span>
                  <span class="send-text" style="word-break: break-word">
                    <span style="margin-right: 10px" v-for="(item, index) in info.vaAccounts" :key="index">{{
                      `${item.accountNo}(${item.vaCurrency})`
                    }}</span>
                  </span>
                </div>
                <div class="send-unit">
                  <span>付款时间：</span>
                  <span
                    class="send-text"
                    v-if="info.payment && info.payment.customMessage && info.payment.customMessage.result"
                    >{{ $moment(info.payment.customMessage.result.payTime).format('YYYY年MM月DD日 HH:mm:ss') }}</span
                  >
                </div>
                <div class="send-unit">
                  <span>付款账户持卡人：</span>
                  <span class="send-text" v-if="info.payment && info.payment.customMessage">
                    {{ info.payment.customMessage.cardholder }}</span
                  >
                </div>
                <div class="send-unit">
                  <span>支付渠道流水：</span>
                  <span
                    class="send-text"
                    v-if="info.payment && info.payment.customMessage && info.payment.customMessage.result"
                  >
                    {{ info.payment.customMessage.result.serialNumber || info.payment.customMessage.reviewer.payNumber
                    }}<a-icon
                      type="copy"
                      style="color: #43d186; margin-left: 5px"
                      @click="
                        onCope(
                          info.payment.customMessage.result.serialNumber ||
                            info.payment.customMessage.reviewer.payNumber,
                        )
                      "
                  /></span>
                </div>
                <div class="send-unit">
                  <span>财务周期：</span>
                  <span
                    class="send-text"
                    v-if="info.payment && info.payment.customMessage && info.payment.customMessage.result"
                  >
                    {{
                      info.payment.customMessage.result.receivedCycle ||
                      info.payment.customMessage.reviewer.receivedCycle
                    }}</span
                  >
                </div>
                <div class="send-unit">
                  <span>财务审核备注：</span>
                  <span
                    class="send-text"
                    v-if="info.payment && info.payment.customMessage && info.payment.customMessage.reviewer"
                  >
                    {{ info.payment.customMessage.reviewer.remark }}</span
                  >
                </div>
                <div class="send-unit" v-if="info.payment && info.payment.customMessage">
                  <span>付款凭证：</span>
                  <img
                    style="margin-left: 10px; cursor: pointer; width: 50px; height: 50px"
                    v-for="i in info.payment.customMessage.certificates"
                    :key="i"
                    :src="i"
                    preview="1"
                  />
                </div>
                <div class="send-unit" v-if="info.payment && info.payment.customMessage">
                  <span>转账汇率换算截图：</span>
                  <img
                    style="margin-left: 10px; cursor: pointer; width: 50px; height: 50px"
                    v-for="i in info.payment.customMessage.exchangeRateCertificates"
                    :key="i"
                    :src="i"
                    preview="1"
                  />
                </div>
              </div>

              <div class="order-title">
                <p>订单信息</p>
              </div>
              <div class="order-items" v-if="!expand['orderInfo']">
                <div class="send-unit">
                  <span>订单编号：</span>
                  <span class="send-text" v-if="info">
                    {{ info.orderNumber }}
                    <a-icon style="color: #43d186; margin-left: 5px" type="copy" @click="onCope(info.orderNumber)"
                  /></span>
                </div>
                <div class="send-unit">
                  <span>创建人：</span>
                  <span class="send-text" v-if="info.orderData && info.orderData.createAccount"
                    >{{ info.orderData.createAccount.fullName }}
                    <span v-if="info.orderData.createAccount.departmentsName"
                      >({{ info.orderData.createAccount.departmentsName }})</span
                    ></span
                  >
                </div>
                <div class="send-unit">
                  <span>创建时间：</span>
                  <span class="send-text" v-if="info">{{
                    $moment(info.whenCreated).format('YYYY年MM月DD日 HH:mm:ss')
                  }}</span>
                </div>
                <div class="send-unit">
                  <span>付款时间：</span>
                  <span
                    class="send-text"
                    v-if="info.payment && info.payment.customMessage && info.payment.customMessage.result"
                    >{{ $moment(info.payment.customMessage.result.payTime).format('YYYY年MM月DD日 HH:mm:ss') }}</span
                  >
                </div>
              </div>

              <template v-if="subscribeDetail">
                <div class="order-title">
                  <p>订阅信息</p>
                </div>
                <div class="order-items">
                  <div class="send-unit">
                    <span>扣款间隔周期：</span>
                    <span class="send-text">
                      {{ subscribeDetail.dayCycle ? subscribeDetail.dayCycle / 7 + '周' : '-' }}
                    </span>
                  </div>

                  <div class="send-unit">
                    <span>每期扣款金额：</span>
                    <span class="send-text">
                      {{
                        subscribeDetail.price
                          ? `${subscribeDetail.price.currency} ${subscribeDetail.price.value.toFixed(2)}`
                          : '-'
                      }}
                    </span>
                  </div>
                  <div class="send-unit">
                    <span>预计下次扣款时间(北京)：</span>
                    <span class="send-text">
                      {{ formatTime(subscribeDetail.nextDeductionTime) }}
                    </span>
                  </div>
                </div>
              </template>

              <template v-if="info">
                <div v-for="item in info.refund" :key="item.uuid">
                  <div class="order-title">
                    <p>退款信息</p>
                  </div>
                  <div class="order-items" v-if="!expand['refund']">
                    <div v-for="i in item.items" :key="i.skuId" class="skus">
                      <div class="send-unit">
                        <span>退款商品：</span>
                        <span class="send-text"> {{ i.skuItemName }}</span>
                      </div>
                      <div class="send-unit">
                        <span>退款数量：</span>
                        <span class="send-text">{{ i.quantity }}</span>
                      </div>
                    </div>
                    <div class="send-unit">
                      <span>退款状态：</span>
                      <span class="send-text">{{ statusMapper[item.refundStatus] }}</span>
                    </div>
                    <div class="send-unit">
                      <span>提交人：</span>
                      <span class="send-text">{{ item.whoCreated || '无' }}</span>
                    </div>

                    <div class="send-unit">
                      <span>提交时间：</span>
                      <span class="send-text">{{
                        item.whenCreated ? $moment(item.whenCreated).format('YYYY年MM月DD日 HH:mm:ss') : '无'
                      }}</span>
                    </div>
                    <div class="send-unit">
                      <span>退款备注：</span>
                      <span class="send-text"> {{ item.message }}</span>
                    </div>
                    <div class="send-unit">
                      <span>退款金额：</span>
                      <span class="send-text">{{
                        `${item.refundAmount}（${info.actualPaymentAmount.currency}）`
                      }}</span>
                    </div>
                    <div class="send-unit">
                      <span>明细：</span>
                      <a-tooltip placement="topLeft">
                        <template slot="title">
                          <div class="trans-item" v-if="actualData['PURCHASED']">
                            <span>购买课时：</span><span>{{ actualData['PURCHASED'].cu }}</span>
                          </div>
                          <div class="trans-item" v-if="actualData['GIFT_PURCHASED']">
                            <span>购买赠课：</span><span>{{ actualData['GIFT_PURCHASED'].cu }}</span>
                          </div>
                          <div class="trans-item" v-if="actualData['GIFT_DISTRIBUTION']">
                            <span>分销赠课：</span><span>{{ actualData['GIFT_DISTRIBUTION'].cu }}</span>
                          </div>
                          <div class="trans-item" v-if="actualData['GIFT_RECOMMEND']">
                            <span>推荐赠课：</span><span>{{ actualData['GIFT_RECOMMEND'].cu }}</span>
                          </div>
                          <div class="trans-item" v-if="actualData['GIFT_HOMEWORK_COMPLETION']">
                            <span>伴学奖赠课：</span><span>{{ actualData['GIFT_HOMEWORK_COMPLETION'].cu }}</span>
                          </div>
                          <div class="trans-item" v-if="actualData['GIFT_SOCIAL_NETWORK_SHARE']">
                            <span>社交平台分享赠课：</span><span>{{ actualData['GIFT_SOCIAL_NETWORK_SHARE'].cu }}</span>
                          </div>
                          <div class="trans-item" v-if="actualData['GIFT_SIGNUP_SHARE']">
                            <span>入学分享赠课：</span><span>{{ actualData['GIFT_SIGNUP_SHARE'].cu }}</span>
                          </div>
                          <div class="trans-item" v-if="actualData['GIFT_SMALL_LESSON']">
                            <span>小班课专属赠课：</span><span>{{ actualData['GIFT_SMALL_LESSON'].cu }}</span>
                          </div>
                          <div class="trans-item" v-if="actualData['GIFT_SOCIAL_NETWORK_SHARE']">
                            <span>分享赠课额度：</span><span>{{ actualData['GIFT_SOCIAL_NETWORK_SHARE'].quota }}</span>
                          </div>
                        </template>
                        <span class="send-text" style="color: #3372fe; cursor: pointer">退款课时明细</span>
                      </a-tooltip>
                    </div>
                    <div class="send-unit" v-if="item.refundStatus === 'OK'">
                      <span>实际退款金额：</span>
                      <span class="send-text">{{ `${item.actualRefundAmount}（${item.actualRefundCurrency}）` }}</span>
                    </div>
                    <div class="send-unit" v-if="item.confirmForm">
                      <span>退款完成时间：</span>
                      <span class="send-text">{{
                        $moment(item.confirmForm.refundTime).format('YYYY年MM月DD日 HH:mm:ss')
                      }}</span>
                    </div>
                    <div class="send-unit" v-if="item.confirmForm">
                      <span>退款处理人：</span>
                      <span class="send-text">{{ item.confirmForm.auditor }}</span>
                    </div>
                    <div class="send-unit" v-if="item.confirmForm">
                      <span>退款截图：</span>
                      <img v-for="i in item.confirmForm.certificates" :src="i" :key="i" preview="1" alt="" />
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div v-else class="empty">
            <p>暂无数据</p>
            <img src="@/assets/icons/pic_emptypage.svg" alt="" />
          </div>
        </div>
        <div class="order-infoRight">
          <div class="order-title">
            <p>
              寄送
              <span>
                <a-icon
                  @click="editPostOrder"
                  type="edit"
                  v-if="
                    info.orderData.approveStatus === 'PASSED' &&
                    info.orderStatus === 'PAYMENT_RECEIVED' &&
                    info.orderData.logisticsStatus === 'PENDING_CONFIRM'
                  "
                />
              </span>
            </p>
          </div>
          <div class="order-items" v-if="info.logistics">
            <div class="send-unit">
              <span>收货人</span>
              <span class="send-text"> {{ info.logistics.receiver.name }}</span>
            </div>
            <div class="send-unit">
              <span>收货人电话</span>
              <span class="send-text">{{ info.logistics.receiver.phoneNumber }}</span>
            </div>
            <div class="send-unit send-address">
              <span>收货地址</span>
              <span class="send-text"
                >{{ countryLabel }}{{ stateLabel }}{{ cityLabel }}{{ info.logistics.receiver.address.street }}</span
              >
            </div>
            <div class="send-unit">
              <span>邮政编码</span>
              <span class="send-text">{{ info.logistics.receiver.postCode }}</span>
            </div>
            <div class="send-unit">
              <span>寄送状态</span>
              <span class="send-text">{{ initTabsList(info.logistics.status, deliveryStatus)[0].label }}</span>
            </div>
            <div class="send-unit">
              <span>物流公司</span>
              <span class="send-text" v-if="info.logistics.logisticsCompany">{{
                initTabsList(info.logistics.logisticsCompany, logisticsCompany)[0].label ||
                info.logistics.logisticsCompany
              }}</span>
            </div>
            <div class="send-unit">
              <span>物流编号</span>
              <span class="send-text"
                >{{ info.logistics.number }}
                <a-icon type="copy" v-if="info.logistics.number" @click="onCope(info.logistics.number)"
              /></span>
            </div>
          </div>
          <div v-else class="empty">
            <p>暂无数据</p>
            <img src="@/assets/icons/pic_emptypage.svg" alt="" />
          </div>
        </div>
      </div>
      <a-modal
        title="编辑寄送"
        :visible="visible"
        @ok="saveSendInfo"
        @cancel="cancelSendInfo"
        okText="提交"
        cancelText="取消"
      >
        <a-form class="ant-advanced-search-form" :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }" :form="form">
          <a-form-item label="收货人">
            <a-input v-decorator="['name', { rules: [{ required: true, message: '请选择收货人!' }] }]" />
          </a-form-item>

          <a-form-item label="收货人电话">
            <a-input
              v-decorator="[
                'phoneNumber',
                {
                  rules: [{ required: true, message: '手机!' }],
                },
              ]"
              style="width: 100%"
            >
              <a-select
                slot="addonBefore"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                v-decorator="['prefix', { initialValue: '+86' }]"
                style="width: 70px"
              >
                <a-select-option :value="i" v-for="i of areaCodeArr" :key="i">
                  {{ i }}
                </a-select-option>
              </a-select>
            </a-input>
          </a-form-item>

          <a-form-item label="收货国家/地区">
            <a-select
              show-search
              placeholder="收货国家/地区"
              option-filter-prop="children"
              @change="handleCountryChange"
              allowClear
              v-decorator="['address.country', { rules: [{ required: true, message: '请选择收货国家/地区!' }] }]"
            >
              <a-select-option :value="i.value" v-for="(i, index) of countryOptions" :key="index">
                {{ i.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="州/省">
            <a-select
              show-search
              allowClear
              placeholder="请选择"
              option-filter-prop="children"
              @change="handleProvinceChange"
              v-decorator="['address.province', { rules: [{ required: true, message: '请选择州/省!' }] }]"
              :options="provinceOptions"
            />
          </a-form-item>

          <a-form-item label="城市">
            <a-select
              show-search
              allowClear
              placeholder="请选择"
              option-filter-prop="children"
              :filter-option="cityFilterOption"
              v-decorator="['address.city', { rules: [{ required: true, message: '请选择城市!' }] }]"
              @search="handleCitySearch"
              @change="handleCityChange"
              :loading="cityLoading"
            >
              <template v-if="cityOptions.length">
                <a-select-option :value="i.key" v-for="(i, index) of cityOptions" :item="i">
                  {{ i.label }}
                </a-select-option>
              </template>
            </a-select>
          </a-form-item>
          <a-form-item label="街道">
            <a-input v-decorator="['address.street', { rules: [{ required: true, message: '请选择街道!' }] }]" />
          </a-form-item>

          <a-form-item label="邮政编码">
            <a-input v-decorator="['postCode', { rules: [{ required: true, message: '请填写邮政编码!' }] }]" />
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
    <div class="spin-wrap" v-show="loading">
      <a-spin size="large" style="padding-top: 20%" />
    </div>
    <a-modal
      width="700px"
      title="确认退款信息"
      :visible="visibleRefund"
      @ok="saveRefund"
      @cancel="cancelRefund"
      okText="确定"
      cancelText="取消"
      :okButtonProps="{
        props: {
          loading: buttonLoading,
        },
      }"
    >
      <a-table :columns="refundColumns" :data-source="refundData" :loading="refundLoading">
        <span slot="type" slot-scope="type, record">{{ initOptionsType(record, categoryArr) }}</span>
        <span slot="subject" slot-scope="subject">{{ initTabsList(subject, subjectArr)[0].label }}</span>
        <span slot="totalCu" slot-scope="totalCu, record">
          {{ initData(record) }}
        </span>

        <span slot="quota" slot-scope="quota, record">
          {{ initRefundData(record) }}
        </span>
      </a-table>

      <a-form :form="refundForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" style="margin-top: 10px">
        <a-form-item v-if="!isSubscribe2">
          <template slot="label">
            <a-tooltip :overlayStyle="{ maxWidth: '500px' }">
              <i class="explain"></i>
              <template slot="title">
                <p>试学期退费：该订单为新生报班订单，并且科目参与试学期优惠，以及该订单消耗课时的数量<=2</p>
                <p>非试学期退费：该科目未参与试学期优惠；或者该科目参与试学期优惠，该订单消耗课时的数量>2</p>
              </template>
            </a-tooltip>
            <span>退费类型</span>
          </template>
          <a-select
            style="width: 250px"
            v-decorator="['type', { initialValue: refundTypeDefaultValue }]"
            placeholder="请选择"
            @change="handleChangeType"
          >
            <a-select-option
              :value="item.value"
              v-for="(item, index) in refundType"
              :key="index"
              :disabled="item.disabled"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="isSubscribe2" label="退款模式">
          <a-select
            style="width: 250px"
            v-decorator="[
              'refundType',
              { initialValue: refundTypeInitialValue, rules: [{ required: true, message: '请选择退款模式!' }] },
            ]"
            placeholder="请选择"
            :options="refundTypeOptions"
            @change="handleChangeRefundType"
          >
          </a-select>
        </a-form-item>
        <a-form-item>
          <template slot="label">
            <a-tooltip :overlayStyle="{ maxWidth: '500px' }">
              <template slot="title" v-if="isSubscribe2">
                <p>退费订单金额计算方式：</p>
                <p>退费模式是代金券，退费金额=（订单总金额/ 购买课时 * 剩余购买课时 - 礼品价格）</p>
                <p>退费模式是纯退费，退费金额=（订单总金额/ 购买课时 * 剩余购买课时 - 礼品价格）*（100%-3%）</p>
              </template>
              <template slot="title" v-else>
                <p>退费订单金额计算方式：</p>
                <p>试学期退费金额=订单总金额*（100%-3%）</p>
                <p>非试学期退费金额=（订单总金额/ 购买课时 * 剩余购买课时 - 礼品价格）*（100%-3%）</p>
              </template>
              <i class="explain"></i>
            </a-tooltip>
            <span>订单参考退款金额</span>
          </template>
          <span style="color: red">{{ refundMoney.value }}（{{ refundMoney.currency }}）</span>
        </a-form-item>

        <!-- <div style="display: inline-flex; algin-items: center">
        
        </div> -->
        <a-form-item label="退款金额">
          <a-input
            style="width: 200px"
            placeholder="请填写"
            v-decorator="['refundAmount', { rules: [{ required: true, message: '请填写退款金额!' }] }]"
            @change="handleRefundAmountChange"
          />
          <span style="color: red; padding: 0 10px 0 5px">({{ info.actualPaymentAmount.currency }})</span>
        </a-form-item>
        <!-- <a-form-item label="退款原因">
          <a-select style="width: 250px" v-decorator="['reason', { rules: [{ required: true, message: '请选择退款原因!' }] }]" placeholder="请选择">
            <a-select-option :value="item.value" v-for="(item, index) in refundReasonArr" :key="index">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item> -->
        <a-form-item label="飞书审批编号">
          <a-input
            placeholder="请输入"
            v-decorator="['feishuApprovalNo', { rules: [{ required: true, message: '请填写飞书审批编号' }] }]"
            :maxlength="50"
          />
        </a-form-item>
        <a-form-item
          name="reason"
          :help="isSubscribe2 ? undefined : amountIsInconsistent ? undefined : ''"
          :validateStatus="isSubscribe2 ? undefined : amountIsInconsistent ? undefined : 'success'"
          label="退款备注"
          :required="amountIsInconsistentOnSub2"
        >
          <a-textarea
            :placeholder="amountIsInconsistentOnSub2 ? '手动输入金额与退款金额不一致，请输入不一致的原因' : '请输入'"
            v-decorator="['reason', { rules: [{ validator: this.checkAmount, trigger: 'change' }] }]"
            :rows="1"
            :maxlength="500"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import { getProvinceOptions, getCityOptions, getCountryOptions, getLocationNames } from '@/utils/geoLocation';
import { Modal } from 'ant-design-vue';

import {
  orderInfo,
  downPdf,
  cancelOrder,
  sendInvoice,
  checkLogistics,
  logistics,
  countries,
  queryRefundClassHour,
  submitRefundClassHour,
  queryStudentDetailData,
  downReceipt,
  getOrderNumber,
  lookStudentDetailDefaultMobile,
  getSubscribeDetailApi,
  getOrderCuInfo,
  unSubscribeOnWinback,
} from '@/api/headTeacher';

import defaultImg from '@/assets/service_pic_head.png';
import { ACCESS_TOKEN } from '@/store/mutation-types';
import { getLabelFromOptions } from '@/utils/geoLocation/utils/getLabelFromOptions';
import debounce from 'lodash.debounce';
import { isUnknownOption } from '@/utils/geoLocation/utils/isUnknownOption';

const session = storage.createStore(sessionStorage);
const token = storage.get(ACCESS_TOKEN);
export default {
  name: 'OrderDetail',
  data() {
    return {
      studentDetail: {},
      countryOptions: [],
      provinceOptions: [],
      cityOptions: [],
      subscribeDetail: undefined,
      refundType: [
        { label: '试学期退费', value: '试学期退费', disabled: false },
        { label: '非试学期退费', value: '非试学期退费', disabled: false },
      ],
      refundAmount: undefined,
      refundTypeDefaultValue: undefined,
      refundTypeInitialValue: 'COUPON',
      // 退费模式
      refundTypeOptions: [
        { label: '代金券', value: 'COUPON', disabled: false },
        { label: '纯退费', value: 'MONEY', disabled: false },
      ],
      subjectArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '英文', value: 'ENGLISH' },
      ],
      zoomMoble: '',
      invoiceLoading: false,
      receiptLoading: false,
      buttonLoading: false,
      refundMoney: {},
      iconLoading: false,
      orderNumber: '',
      actualData: {},
      cityLoading: false,
      categoryArr: [
        { label: '伴学奖赠课', value: 'GIFT_HOMEWORK_COMPLETION' },
        { label: '购买课时', value: 'PURCHASED' },
        { label: '推荐赠课', value: 'GIFT_RECOMMEND' },
        { label: '分销赠课', value: 'GIFT_DISTRIBUTION' },
        { label: '购买赠课', value: 'GIFT_PURCHASED' },
        { label: '社交平台分享赠课', value: 'GIFT_SOCIAL_NETWORK_SHARE' },
        { label: '入学分享赠课', value: 'GIFT_SIGNUP_SHARE' },
        { label: '小班课专属赠课', value: 'GIFT_SMALL_LESSON' },
        { label: '手动变更课时', value: 'GIFT_ADJUST' },
      ],

      refundReasonArr: [
        { label: '课件', value: '课件' },
        { label: '教材作业', value: '教材作业' },
        { label: '课程体系其他', value: '课程体系其他' },
        { label: '老师教学/服务', value: '老师教学/服务' },
        { label: '班主任服务', value: '班主任服务' },
        { label: '学习规划师服务', value: '学习规划师服务' },
        { label: '系统', value: '系统' },
        { label: '规则', value: '规则' },
        { label: '学生原因', value: '学生原因' },
        { label: '家长原因', value: '家长原因' },
        { label: '竞品', value: '竞品' },
        { label: '家长拒绝进一步沟通', value: '家长拒绝进一步沟通' },
        { label: '其他', value: '其他' },
      ],
      refundForm: this.$form.createForm(this),
      refundData: [],
      refundColumns: [
        {
          title: '课时类型',
          dataIndex: 'type',
          key: 'type',
          scopedSlots: { customRender: 'type' },
        },
        {
          title: '所属科目',
          dataIndex: 'subject',
          key: 'subject',
          scopedSlots: { customRender: 'subject' },
        },
        {
          title: '剩余课时或额度',
          dataIndex: 'totalCu',
          key: 'totalCu',

          scopedSlots: { customRender: 'totalCu' },
        },
        {
          title: '可退课时或额度',
          key: 'quota',
          dataIndex: 'quota',
          scopedSlots: { customRender: 'quota' },
        },
      ],
      refundLoading: false,
      visibleRefund: false,
      loading: false,
      defaultUrl: 'https://cdnwukong.com/public/im/user.png',
      orderId: '',
      areaCodeArr: [],
      countries: null,
      form: this.$form.createForm(this),
      visible: false,
      orderType: [
        { label: '学生续费', value: '续费' },
        { label: '学生补升', value: '补升' },
        { label: '新生报班', value: '新生报班' },
        { label: '国际运费', value: '国际运费' },
        // { label: '其它', value: '其它' },
      ],
      approvalStatus: [
        { label: '审核中', value: 'PENDING' },
        { label: '已通过', value: 'PASSED' },
        { label: '已拒绝', value: 'REJECTED' },
        { label: '已撤销', value: 'CANCEL' },
      ],
      orderStatusArray: [
        { label: '待支付', value: 'PENDING_PAYMENT' },
        { label: '已支付', value: 'PAYMENT_RECEIVED' },
        { label: '已支付待确认', value: 'CONFIRMED' },
        { label: '已取消', value: 'CANCELLED' },

        // { label: '已退款', value: '已退款' },
      ],
      deliveryStatus: [
        { label: '无需发货', value: 'NO_NEED_TO_SHIP' },
        { label: '待确认', value: 'PENDING_CONFIRM' },
        { label: '待发货', value: 'PENDING' },
        { label: '已发货', value: 'SENT' },
        { label: '已取消', value: 'CANCEL' },
        { label: '打包中', value: 'WAITING' },
        { label: '已签收', value: 'FINISH' },
      ],
      logisticsCompany: [
        { label: 'FEDEX联邦', value: 'FEDEX' },
        { label: 'DHL', value: 'DHL' },
        { label: 'EMS', value: 'EMS' },
        { label: '顺丰', value: 'SF' },
        { label: '专线', value: 'SL' },
        { label: '中通', value: 'ZTO' },
      ],
      tabsList: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '新生报班', value: 'NEW_STU_REPORT_CLASS' },
        { label: '续费', value: 'RENEW' },
        { label: '补升', value: 'MAKE_UP' },
        { label: '赠课时', value: 'HANDSEL_COURSE' },
      ],
      confirmStatus: [
        { label: '待支付', value: 'PENDING' },
        { label: '已支付待确认', value: 'PENDING_CONFIRMATION' },
        { label: '已支付', value: 'SUCCESSFUL' },
        { label: '支付失败', value: 'FAILED' },
      ],
      payStyle: [
        { label: '无', value: 'empty' },
        { label: 'PayPal', value: 'PAYPAL' },
        { label: '微信', value: 'WECHAT' },
        { label: '支付宝', value: 'ALIPAY' },
        { label: '新西兰转账', value: 'NEWZEALAND' },
        { label: '跨国转账', value: 'CROSS' },
        { label: '国内转账', value: 'INLAND' },
        { label: '现金', value: 'CASH' },
        { label: '银行卡', value: 'CARD' },
        { label: '其他', value: 'OTHER' },
        { label: 'Latipay', value: 'LATIPAY' },
        { label: 'Stripe', value: 'STRIPE' },
        { label: '转账', value: 'REMITTANCE' },
        { label: '银联', value: 'UPOP' },
        { label: 'klarna', value: 'KLARNA' },
      ],
      statusMapper: {
        PENDING: '待退款',
        OK: '已退款',
        PROCESSING: '退款处理中',
        FAILED: '退款失败',
      },
      paymentStatus: {
        PENDING_PAYMENT: '请求中',
        CONFIRMED: '已确认付款凭证',
        PAYMENT_RECEIVED: '已确认到账',
        CANCELLED: '已取消',
      },
      defaultImg,
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) => `${(this.current - 1) * this.pageSize + (index + 1)}`,
        },
        {
          title: '订单号',
          dataIndex: 'orderNumber',
          key: 'orderNumber',
        },
        {
          title: '订单类型',
          dataIndex: 'orderTags',
          key: 'orderTags',
        },
        {
          title: '商品标签',
          dataIndex: 'productTags',
          key: 'productTags',
          scopedSlots: { customRender: 'productTags' },
        },
        {
          title: '订单金额',
          dataIndex: 'money',
          key: 'money',
          scopedSlots: { customRender: 'money' },
        },
        {
          title: '审核状态',
          dataIndex: 'approveStatus',
          key: 'approveStatus',
          scopedSlots: { customRender: 'approveStatus' },
        },
        {
          title: '订单状态',
          dataIndex: 'orderStatus',
          scopedSlots: { customRender: 'orderStatus' },
        },
        {
          title: '寄送状态',
          dataIndex: 'logisticsStatus',
          scopedSlots: { customRender: 'logisticsStatus' },
        },
      ],
      studentId: '',
      studentProfile: {},
      userInfo: storage.get('userInfo'),
      pageNum: 1,
      search: {
        tags: null,
        orderStatus: undefined,
        orderNumber: undefined,
      },
      searchData: null,
      tableData: null,
      selectedData: null,
      info: {
        orderData: {},
        refund: [],
        orderShipment: {},
        money: {},
      },
      current: 0,
      pageSize: 0,
      allPaice: 0,
      initPagination: {
        total: 0,
        current: 1,
        pageSize: 20,
      },
      tags: undefined,

      expand: {},
    };
  },
  computed: {
    /**
     * 是否展示取消订阅
     */
    showUnsubscription() {
      if (this.isSubscribe2 && ['OK', 'DEDUCTION_FAILED'].includes(this.subscribeDetail.status)) {
        return this.userInfo.roleArr.includes('VIRTUAL_CLASS_ADMIN');
      }
    },
    /**
     * 是否展示退款按钮
     */
    showRefund() {
      const supportedRole = this.isSubscribe2
        ? this.userInfo.roleArr.includes('VIRTUAL_CLASS_ADMIN')
        : ['HEAD_TEACHER_SUPER', 'TEACHING_STAFF_ADMIN', 'TEACHING_STAFF'].includes(this.userInfo.roleName);

      return (
        // 可以操作的角色
        supportedRole &&
        this.info.orderStatus === 'PAYMENT_RECEIVED' &&
        this.info.refund &&
        this.info.refund.length === 0
      );
    },
    /**
     * 退款金额与参考退款金额不一致
     */
    amountIsInconsistent() {
      if (this.refundAmount?.length && this.refundMoney.value >= 0) {
        return Number(this.refundAmount) !== this.refundMoney.value;
      }
      return false;
    },
    /**
     * 金额不一致（仅订阅2.0）
     */
    amountIsInconsistentOnSub2() {
      return this.isSubscribe2 && this.amountIsInconsistent;
    },
    /**
     * 是否订阅2.0 订单
     */
    isSubscribe2() {
      return !!this.subscribeDetail;
    },
    countryLabel() {
      return getLabelFromOptions(this.countryOptions, this.info.logistics.receiver.address.country);
    },
    stateLabel() {
      return getLabelFromOptions(this.provinceOptions, this.info.logistics.receiver.address.province);
    },
    cityLabel() {
      return getLabelFromOptions(this.cityOptions, this.info.logistics.receiver.address.city);
    },
  },
  created() {
    this.orderId = this.$route.query.orderId;
    this.logisticsStatus = this.$route.query.logisticsStatus;
    this.orderStatus = this.$route.query.orderStatus;
    this.approveStatus = this.$route.query.approveStatus;

    if (this.$route.query.studentId) {
      this.queryStudentDetailData(this.$route.query.studentId);
    } else {
      this.studentProfile = session.get('studentProfile');
    }
    this.studentId = this.$route.query.studentId || this.studentProfile.uuid;
    this.onCheckLogistics();

    this.getOrderInfo();
  },
  mounted() {
    getCountryOptions().then((data) => (this.countryOptions = data));
  },

  methods: {
    async checkAmount(rule, value) {
      if (!this.isSubscribe2) {
        return Promise.resolve();
      }
      if (!value?.length && this.amountIsInconsistentOnSub2) {
        return Promise.reject('请填退款写不一致的原因');
      }
      return Promise.resolve();
    },
    // 退款金额变化
    async handleRefundAmountChange(v) {
      this.refundAmount = v.target.value;
      if (this.isSubscribe2) {
        // this.form.validateFields()
        // await this.form.validateFields(['reason'],{ force: true },()=>{
        //   console.log('check')
        // })
      }
    },
    handleUnSubscription() {
      Modal.confirm({
        title: '是否取消订阅',
        content: '取消订阅后系统会取消学员扣费计划，并邮件通知家长',
        okText: '取消订阅',
        cancelText: '放弃',
        centered: true,
        class: 'logout',
        onOk: async () => {
          await unSubscribeOnWinback(this.subscribeDetail.uuid);
          this.$message.success('取消订阅成功');
          this.getOrderInfo();
          return true;
        },
        onCancel: () => {},
      });
    },
    async handleCountryChange(v) {
      getProvinceOptions(v).then((res) => (this.provinceOptions = res));
      this.cityOptions = [];
      this.form.setFieldsValue({
        nationalTimeZone: undefined,
        address: {
          country: v,
          province: undefined,
          city: undefined,
        },
      });
    },
    async handleProvinceChange(v) {
      getCityOptions({
        countryId: this.form.getFieldValue('address.country'),
        provinceId: v,
        showDoubleLanguage: true,
      }).then((res) => (this.cityOptions = res));
      this.form.setFieldsValue({
        address: {
          ...this.form.getFieldValue('address'),
          city: undefined,
        },
      });
    },
    handleCitySearch: debounce(function (v) {
      this.cityOptions = [];
      this.cityLoading = true;
      getCityOptions({
        countryId: this.form.getFieldValue('address.country'),
        provinceId: this.form.getFieldValue('address.province'),
        city: v,
        showDoubleLanguage: true,
      })
        .then((res) => {
          this.cityOptions = res;
          this.cityLoading = false;
        })
        .catch(() => {
          this.cityLoading = false;
        });
    }, 500),
    cityFilterOption(value, o) {
      const input = value ? value?.trim() : value;
      if (!input) return true;
      return o?.data?.attrs?.item?.label.toLowerCase().includes(input.toLowerCase());
    },
    handleCityChange(v, o) {
      const country = this.form.getFieldValue('address.country');
      const state = this.form.getFieldValue('address.province');
      // 如果没有选中国家或者省份，则通过城市反填数据
      if (!country || !state) {
        const option = o?.data?.attrs?.item;
        if (!option || isUnknownOption(option)) return;
        this.handleCountryChange(option.countryId);
        // 这里先给表单赋值，因为 handlProvinceChange 是从表单中取的国家
        // 最好通过参数传递
        const address = this.form.getFieldValue('address');
        option.countryId && this.form.setFieldsValue({ address: { ...address, country: option.countryId } });
        option.provinceId && this.form.setFieldsValue({ address: { ...address, province: option.provinceId } });
        this.handleProvinceChange(option.provinceId);
        // this.form.setFieldsValue({ address: { ...address, country: option.countryId, province: option.provinceId } });
      }
    },
    handleChangeType(e) {
      this.queryRefundClassHour(e);
    },
    handleChangeRefundType(e) {
      this.queryRefundClassHour('非试学期退费', e);
    },
    formatTime(time) {
      if (!time) return '-';
      return this.$moment(time).format('YYYY-MM-DD HH:mm:ss');
    },
    queryStudentDetailData(studentId) {
      const params = {
        studentId,
      };
      queryStudentDetailData(params).then((res) => {
        const data = res.data.content;
        this.studentDetail = data;

        this.studentProfile = {
          headImageUrl: data.headImageUrl,
          uuid: data.uuid,
          fullName: data.fullName,
          code: data.code,
        };
      });
    },
    initRefundData(record) {
      if (record.type === 'GIFT_SOCIAL_NETWORK_SHARE' && record.remainingQuota > 0) {
        return `${record.remainingQuota}`;
      }
      return `${record.remainingCu}`;
    },
    initData(record) {
      if (record.type === 'GIFT_SOCIAL_NETWORK_SHARE' && record.remainingQuota > 0) {
        return `${record.remainingQuota}/${record.quota}`;
      }
      return `${record.remainingCu}/${record.totalCu}`;
    },
    initOptionsType(record, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === record.type) {
          if (record.type === 'GIFT_SOCIAL_NETWORK_SHARE' && record.remainingQuota > 0) {
            // 分享赠课额度
            name = '分享赠课额度';
          } else {
            name = item.label;
          }
        }
      });
      return name;
    },
    saveRefund() {
      this.refundForm.validateFields((err, values) => {
        if (!err) {
          this.buttonLoading = true;
          const params = {
            refundReference: this.info.orderNumber, // 订单编号
            studentCode: this.studentProfile.code,
            reason: values.reason,
            refundAmount: values.refundAmount,
            feishuApprovalNo: values.feishuApprovalNo,
            type: this.isSubscribe2 ? '非试学期退费' : values.type,
            refundType: values.refundType || 'MONEY',
          };
          submitRefundClassHour(params)
            .then((res) => {
              this.$message.success('提交成功');
              this.getOrderInfo();
              this.visibleRefund = false;
              this.buttonLoading = false;
            })
            .catch(() => {
              this.buttonLoading = false;
            });
        }
      });
    },
    async queryRefundClassHour(type, refundType) {
      this.refundLoading = true;
      const params = {
        studentId: this.studentId,
        reference: this.info.orderNumber,
        refundType: type,
        type: refundType,
      };
      queryRefundClassHour(params)
        .then((res) => {
          this.refundLoading = false;
          this.iconLoading = false;
          this.refundData = res.data.content.cuRespList;

          if (this.refundData.length > 0) {
            this.visibleRefund = true;
            this.refundMoney = res.data.content.money;
          } else {
            this.$message.warning('该订单可退课时为0');
          }
        })
        .catch(() => {
          this.iconLoading = false;
          this.refundLoading = false;
        });
    },
    cancelRefund() {
      this.visibleRefund = false;

      this.refundForm.resetFields();
    },
    async applyForRefund() {
      this.iconLoading = true;
      const type = await this.initRefundType();
      if (this.isSubscribe2) {
        this.queryRefundClassHour('非试学期退费', this.refundTypeInitialValue);
      } else {
        this.queryRefundClassHour(type);
      }
    },
    async initRefundType() {
      const { data } = await getOrderCuInfo({ studentId: this.studentId, orderNum: this.info.orderNumber });
      const usedCu = data.usedChineseCu + data.usedMathCu + data.usedEnglishCu;
      let type;

      if (this.info.tags.includes('试学期退费') && usedCu <= 2) {
        type = '试学期退费';
      } else {
        type = '非试学期退费';
        this.refundType = [
          { label: '试学期退费', value: '试学期退费', disabled: true },
          { label: '非试学期退费', value: '非试学期退费', disabled: false },
        ];
      }
      this.refundTypeDefaultValue = type;
      return type;
    },
    cancelSendInfo() {
      this.visible = false;
    },
    saveSendInfo(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const datas = values;
          const cityKeys = datas.address.city?.split('-') ?? [];
          datas.address.city = cityKeys?.[cityKeys?.length - 1];
          datas.phoneNumber = `${datas.prefix} ${datas.phoneNumber}`;
          delete datas.prefix;

          const o = {
            uuid: this.info.uuid,
            data: { studentId: this.studentProfile.uuid, studentCode: this.studentProfile.code, logisticReq: datas },
          };
          logistics(o).then(() => {
            this.$message.success('提交成功');
            this.getOrderInfo();
            this.cancelSendInfo();
          });
        }
      });
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    onExpand(data) {
      this.$set(this.expand, data, !this.expand[data]);
    },

    onCope(num) {
      this.$copyText(num).then(
        (res) => {
          this.$message.success('已成功复制，可直接去粘贴');
        },
        (err) => {
          this.$message.error('复制失败');
        },
      );
    },
    onCheckLogistics() {
      const parame = {
        studentCode: this.studentProfile.code,
      };
      checkLogistics(parame).then((res) => {
        if (res.data.content) {
          session.set('orderId', res.data.content.uuid);
        }
      });
    },
    sendOrder() {
      sendInvoice({ uuid: this.info.uuid }).then((res) => {
        this.$message.success('发送成功');
      });
    },
    cancalOrder() {
      this.$confirm('是否取消订单', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning',
        showClose: false,
      })
        .then(() => {
          cancelOrder({ uuid: this.info.uuid }).then((res) => {
            this.getOrderInfo();
            this.$message.success('取消成功');
          });
        })
        .catch(() => {});
    },
    saveAs(data, name, type) {
      const urlObject = window.URL || window.webkitURL || window;
      const blob = new Blob([data]);
      const aTag = document.createElement('a');
      aTag.href = urlObject.createObjectURL(blob);
      aTag.download = name;
      aTag.click();
      if (type === 'invoice') {
        this.invoiceLoading = false;
      } else if (type === 'receipt') {
        this.receiptLoading = false;
      }
    },
    downReceipt() {
      this.receiptLoading = true;
      const params = {
        orderId: this.orderId,
      };
      downReceipt(params).then((res) => {
        const fileUrl = res.data.content;
        const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
        const url2 = fileUrl.replace(/\\/g, '/');
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url2, true);
        xhr.responseType = 'blob';

        xhr.onload = () => {
          if (xhr.status === 200) {
            this.saveAs(xhr.response, fileName, 'receipt');
          }
        };
        xhr.send();
      });
    },
    downInvoice() {
      this.invoiceLoading = true;
      downPdf({ uuid: this.info.taxInvoice.uuid }).then((res) => {
        // downloadFile(fileUrl) {
        const fileUrl = res.data.content;
        const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);

        const url2 = fileUrl.replace(/\\/g, '/');
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url2, true);
        xhr.responseType = 'blob';
        // xhr.setRequestHeader('Authorization', 'Basic a2VybWl0Omtlcm1pdA==');
        xhr.onload = () => {
          if (xhr.status === 200) {
            this.saveAs(xhr.response, fileName, 'invoice');
          }
        };
        xhr.send();
        // },
      });
    },
    editOrder() {
      window.open(
        `${process.env.VUE_APP_MAKEUP_URL}?studentCode=${this.studentProfile.code}&businessId=57526073-055c-4f58-adb0-ba555ea9c8b2&merchantId=2d6e71ae-c0d6-40b2-9633-48e7dd9d1cea&token=${token}&username=${this.userInfo.fullName}&email=${this.userInfo.email}&orderId=${this.orderId}&origin=ff`,
      );
    },
    onOrderInfo() {
      if (!this.userInfo.fullName) {
        this.$message.warning('未携带用户名参数');
        return false;
      }
      if (!this.userInfo.email) {
        this.$message.warning('未携带参数邮箱');
        return false;
      }
      const queryString = Object.entries({
        origin: 'ff',
        orderId: this.orderId,
        studentCode: this.studentProfile.code,
        businessId: '57526073-055c-4f58-adb0-ba555ea9c8b2',
        merchantId: '2d6e71ae-c0d6-40b2-9633-48e7dd9d1cea',
        token,
        username: this.userInfo.fullName,
        email: this.userInfo.email,
        targetUrl: window.location.href,
      })
        .map(([k, v]) => `${k}=${encodeURIComponent(v)}`) // 带有特殊字符的需要编码
        .join('&');

      window.open(`${process.env.VUE_APP_MAKEUP_URL}?${queryString}`);
    },
    async editPostOrder() {
      this.visible = true;
      this.form.resetFields();
      countries().then((i) => {
        this.countries = i.data.content;
        this.areaCodeArr = this.unique(i.data.content);
      });

      // 兼容数据问题
      if (!this.studentDetail?.data?.address?.state && this.studentDetail?.data?.address?.province) {
        this.studentDetail.data.address.state = this.studentDetail.data.address.province;
      }

      getCountryOptions().then((countryOptions) => {
        this.countryOptions = countryOptions;

        console.log(this.studentDetail.data);
        const country = this.studentDetail.data?.address?.country;
        const province = this.studentDetail.data?.address?.state;
        const city = this.studentDetail.data?.address?.city;
        const street = this.studentDetail.data?.address?.street;
        const postCode = this.studentDetail.data?.address?.postalCode;
        const name = this.studentDetail.data?.recipientName;

        getProvinceOptions(country).then((data) => (this.provinceOptions = data));
        getCityOptions({ countryId: country, provinceId: province, showDoubleLanguage: true }).then((data) => {
          this.cityOptions = data;
        });

        this.form.setFieldsValue({
          nationalTimeZone: undefined,
          postCode,
          name,
          address: {
            country,
            province,
            city,
            street,
          },
        });
      });
    },
    unique(arr) {
      const arr1 = []; // 新建一个数组来存放arr中的值
      for (let i = 0, len = arr.length; i < len; i++) {
        if (arr1.indexOf(arr[i].areaCode) === -1) {
          arr1.push(arr[i].areaCode);
        }
      }
      return arr1;
    },
    initMobile(data) {
      if (!data) return;
      let str = data.split(' ')[1].toString().replace(/ /g, '');
      const len = str.length;
      switch (true) {
        case len > 11:
          str = `${str.substr(0, 3)} ${str.substr(3, 4)} ${str.substr(7, 4)}`;
          break;
        case len > 7:
          str = `${str.substr(0, 3)} ${str.substr(3, 4)} ${str.substr(7)}`;
          break;
        case len > 3:
          str = `${str.substr(0, 3)} ${str.substr(3)}`;
          break;
        default:
      }
      this.zoomMoble = `zoomphonecall://${data.split(' ')[0]}${str}?cat=seccall`;
      window.location.href = this.zoomMoble;
    },
    zoomMobleFun() {
      // 先查默认电话
      const params = {
        orderId: this.orderId,
        studentId: this.studentId,
      };
      lookStudentDetailDefaultMobile(params).then((res) => {
        this.initMobile(res.data.content);
      });
    },
    getOriginalPrice(currency, prices) {
      return prices.filter((i) => currency == i.currency);
    },

    async getOrderInfo() {
      this.loading = true;

      try {
        let handle = () => ({});

        if (this?.orderId) {
          handle = () =>
            orderInfo({
              orderId: this?.orderId,
            });
        } else if (this.$route?.query?.orderNumber) {
          handle = () =>
            getOrderNumber({
              orderNumber: this.$route?.query?.orderNumber,
            });
        }

        const res = await handle();

        // if (res.data?.tags?.includes('订阅订单')) {
        const order = await getSubscribeDetailApi({
          orderId: this.orderId || null,
          orderNum: this.$route?.query?.orderNumber || null,
        });

        this.subscribeDetail = order.data.content;
        // }

        this.info = res.data;
        console.log('???', this.subscribeDetail);
        const country = res.data?.logistics?.receiver?.address?.country;
        const state = res.data?.logistics?.receiver?.address?.province;

        if (country) {
          getProvinceOptions(country).then((data) => (this.provinceOptions = data));
          if (state) {
            getCityOptions({ countryId: country, provinceId: state, showDoubleLanguage: true }).then(
              (data) => (this.cityOptions = data),
            );
          }
        }
        this.actualData = res.data.actualRefund || {};

        let pice = 0;
        this.info.orderLines.forEach((item) => {
          if (!item.productSnapshot.prices) {
            // eslint-disable-next-line no-param-reassign
            item.productSnapshot.prices = item.productSnapshot.productData.price;
          }
          pice +=
            (this.getOriginalPrice(this.info.actualPaymentAmount.currency, item.productSnapshot.prices).length > 0
              ? this.getOriginalPrice(this.info.actualPaymentAmount.currency, item.productSnapshot.prices)[0].value
              : 0) * item.quantity;
        });
        this.allPaice = pice.toFixed(2);
      } finally {
        this.loading = false;
      }
    },
    initTabsList(value, data) {
      const arr = data.filter((i) => i.value === value);
      return arr.length ? arr : [{}];
    },
    toOrder() {
      if (!this.userInfo.fullName) {
        this.$message.warning('未携带用户名参数');
        return false;
      }
      if (!this.userInfo.email) {
        this.$message.warning('未携带参数邮箱');
        return false;
      }
      window.open(
        `${process.env.VUE_APP_MAKEUP_URL}?studentCode=${this.studentProfile.code}&businessId=57526073-055c-4f58-adb0-ba555ea9c8b2&merchantId=2d6e71ae-c0d6-40b2-9633-48e7dd9d1cea&token=${token}&username=${this.userInfo.fullName}&email=${this.userInfo.email}&targetUrl=${window.location.href}&origin=ff`,
      );
    },
    onSearch() {
      // this.initLearningReport(this.pageNum);
    },
  },
};
</script>

<style lang="less" scoped>
.order-main {
  .backMyStudent {
    span {
      font-size: 24px;
      color: #333;
    }
    .backStudentName {
      color: #43d186;
    }
  }
  .order-table {
    width: 65%;
    padding: 20px;
    .reports-search {
      display: flex;
      justify-content: space-between;
      margin: 20px 0;
    }
  }
  .order-infoRight {
    flex: 1;
    border-left: 1px solid #eee;
    padding: 0 0 0 20px;
  }
  .order-info {
    width: 70%;
    color: #333;
    padding: 0 20px 0 0;
  }
  .order-code {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
  }
  .order-name {
    display: flex;
    justify-content: space-between;
    p {
      font-size: 18px;
      font-weight: 600;
      color: #333;
    }
  }
  .order-status {
    font-size: 16px;
    color: #999;
    span {
      color: #333;
    }
  }
  .order-title {
    width: 100%;
    display: flex;
    font-weight: 600;
    justify-content: space-between;
    font-size: 16px;
    color: #333;
    p {
      &::before {
        content: '';
        display: block;
        float: left;
        margin: 4px 6px 0 0;
        width: 3px;
        height: 16px;
        background-color: #41cc83;
        border-radius: 0px 2px 2px 0px;
      }
    }
  }
  .order-customer {
    display: flex;
    justify-content: flex-start;
    font-size: 16px;
    align-items: center;
    color: #333;
    margin-bottom: 20px;
    img {
      width: 42px;
      height: 42px;
      margin-right: 20px;
    }
    p {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 3px;
      &:hover {
        cursor: pointer;
      }
    }
    .order-customerPhone {
      color: #999;
      font-size: 14px;
      margin: 0;
      span {
        color: #009cff;
      }
    }
  }
  .order-commodity {
    display: flex;

    align-items: center;
    margin-bottom: 20px;
    .num {
      width: 42px;
      height: 42px;
      text-align: center;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .commodity-info {
      flex: 1;
      margin-left: 12px;
      .title {
        font-size: 16px;
        font-weight: 600;
        margin-right: 10px;
        line-height: 22px;
      }
      p {
        margin-bottom: 0;
      }
      .commodity-suject {
        display: inline-block;
        font-size: 10px;
        padding: 2px 4px;
        border-radius: 6px;
        color: #ff5353;
        margin-right: 10px;
        background-color: rgba(255, 83, 83, 0.1);
        border: 1px solid #ff5353;
      }
      .commodity-new {
        display: inline-block;
        font-size: 10px;
        padding: 2px 4px;
        border-radius: 6px;
        margin-right: 10px;
        color: #43d186;
        border: 1px solid #43d186;
      }
      .price {
        font-size: 12px;
        color: #999;
        margin-top: 7px;
      }
      .actuallyPaid {
        color: #333;
      }
      .number {
        font-size: 14px;
        color: #666;
      }
      & > div {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .order-remark {
    border-top: 1px solid #eee;
    margin-top: 20px;
    padding-top: 20px;
    .total {
      font-size: 16px;
      color: #333;
      font-weight: 400;
      text-align: right;
      padding-right: 10px;
      span {
        font-weight: 600;
      }
    }
    .all-price {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      p {
        display: inline-block;
        width: 33%;
      }
    }
    p {
      color: #999;
      font-size: 14px;
    }
    span {
      color: #333;
    }
  }
  .order-items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .skus {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  .send-unit {
    width: 50%;
    font-size: 14px;
    color: #999;
    margin-bottom: 20px;
    display: flex;
    img {
      width: 40px;
      height: 40px;
    }
  }
  .send-address {
    width: 1000%;
  }
  .send-text {
    color: #333;
    margin-left: 10px;
    flex: 1;
  }
  .empty {
    font-size: 16px;
    color: #999;
    padding-top: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
  }
}
.header-top {
  padding: 18px 20px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.header-title {
  font-size: 16px;
  font-weight: 600;
}
.student-avtor {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  img {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}
.student-info {
  font-size: 16px;
  color: #333;
  margin-top: 5px;
  display: flex;
  align-items: center;
}
.operator-btn {
  display: inline-block;
  color: #1f2d3d;
  font-size: 13px;
  padding: 5px 8px;
  padding: 0 8px;
  height: 24px;
  line-height: 22px;
  margin-left: 10px;
  border: 1px solid rgba(31, 45, 61, 0.18);
  border-radius: 2px;
  cursor: pointer;
  &:hover {
    border: 1px solid #00bf8a;
    color: #00bf8a;
  }
}
.order-wrap {
  background-color: #fff;
  padding: 18px 20px 0;
  display: flex;
}
.spin-wrap {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: calc(100vh - 64px);
  background-color: rgba(255, 255, 255, 0.7);
  text-align: center;
  z-index: 100;
}
.right-wrap {
  button {
    margin-left: 10px;
  }
}
.explain {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('~@/assets/explain.png') no-repeat center;
  background-size: 100%;
  margin: 0 6px 0 0px;
}
</style>
